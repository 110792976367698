import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, PageSubSection} from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import uc2LsafetyTable from "../../../assets/UC-2L-045-safety-small-V3.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Safety Data and Adverse Reactions for KEYNOTE-045`,
    keywords: `keynote-045 safety data, adverse reactions for second-line monotherapy for urothelial carcinoma`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the most common adverse reactions associated with the KEYNOTE-045 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/urothelial-carcinoma-first-line-monotherapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with locally advanced or metastatic urothelial carcinoma (mUC) who are not eligible for any platinum-containing chemotherapy."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/urothelial-carcinoma-first-line-monotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const uc2SafetyAlt = "Safety Data for KEYNOTE⁠-⁠045"

//from /src/util/data/indications.js
const indicationId = 16;
const jobCode = jobCodes[15].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: '1L Monotherapy Cisplatin Ineligible', url: '/safety/adverse-reactions/urothelial-carcinoma-first-line-monotherapy' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const ucL2DataTableFootnotes = [
    {
        label: 'c.',
        text: 'Chemotherapy: Paclitaxel, docetaxel, or vinflunine.'
    },
    {
        label: 'd.',
        text: 'Graded per National Cancer Institute-Common Terminology Criteria for Adverse Events (NCI-CTCAE) v4.0.'
    },
    {
        label: 'e.',
        text: 'Includes asthenia, fatigue, malaise, and lethargy.'
    },
    {
        label: 'f.',
        text: 'Includes back pain, myalgia, bone pain, musculoskeletal pain, pain in extremity, musculoskeletal chest pain, musculoskeletal discomfort, and neck pain.'
    },
    {
        label: 'g.',
        text: 'Includes rash maculopapular, rash, genital rash, rash erythematous, rash papular, rash pruritic, rash pustular, erythema, drug eruption, eczema, eczema asteatotic, dermatitis contact, dermatitis acneiform, dermatitis, seborrheic keratosis, and lichenoid keratosis.'
    },
]

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Safety Data for KEYNOTE&#8288;-&#8288;045'}
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <PageSubSection title="Adverse Reaction Profile of KEYTRUDA in Certain Patients With Locally Advanced or Metastatic Urothelial Carcinoma Post–Platinum Failure">
                            <DataTable
                                title='Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA'
                                footnotes={ucL2DataTableFootnotes}
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `<p>KEYTRUDA was discontinued due to adverse reactions in 8% of patients. The most common adverse reaction resulting in permanent discontinuation of KEYTRUDA was pneumonitis (1.9%).</p>
                                        <p>Adverse reactions leading to interruption of KEYTRUDA occurred in 20% of patients; the most common (≥1%) were urinary tract infection (1.5%), diarrhea (1.5%), and colitis (1.1%).</p>
                                        <p>Serious adverse reactions occurred in 39% of patients treated with KEYTRUDA. The most frequent serious adverse reactions (≥2%) in patients treated with KEYTRUDA were urinary tract infection, pneumonia, anemia, and pneumonitis.</p>`
                                    },
                                    
                                ]}  
                                mobileImage={() => <img src={uc2LsafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={uc2SafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/UC-2L-045-safety-large-V3.png" alt={uc2SafetyAlt}/>}
                            />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
